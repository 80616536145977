import * as React from 'react';
import { useRef } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import MenuTriangle from '../../assets/menuTriangle.asset.svg';
import { IMenuItem } from '../../interfaces/IMenuItemQueryResult';
import { ChildMenuItemTile } from '../ChildMenuItemTile/ChildMenuItemTile';

import * as styles from './MenuItem.module.scss';


export interface IMenuItemProps{
    text: string;
    id: number;
    childNodes?: IMenuItem[];
    submenuTitle?: string;
}

export const MenuItem = (props: IMenuItemProps) => {
    const { text, childNodes, id, submenuTitle, url } = props;
    const checkboxRef = useRef(null);

    return(
        <div className={ styles.menuItem } >
            <input type="checkbox" className={ styles.checkbox } id={ `menu-item-${ id }` } /> {/*onChange={ (event: React.ChangeEvent<HTMLInputElement>) => { if(event.target.togg === false){  }} } />*/}
            <label htmlFor={ `menu-item-${ id }` }>
                <MenuTriangle  />
                <span>{ text }</span>
            </label>
            <div className={ styles.childMenuContainer }>
                {
                    submenuTitle && (
                        <span className={ styles.childMenuTitle }>{ submenuTitle }</span>
                    )
                }
                <div className={ styles.childMenuTileContainer }>
                    {
                        childNodes && childNodes.map(childNode => {
                            return (
                                <ChildMenuItemTile key={ childNode.id } title={ childNode.Title } iconName={ childNode.Iconname } url={ childNode.URL || "/"}/>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}